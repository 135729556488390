/** =================== MONTSERRAT =================== **/

/** Montserrat Light **/
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  src: url('./Montserrat-Light.woff2') format('woff2');
  font-display: swap;
}

/** Montserrat Regular **/
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: url('./Montserrat-Regular.woff2') format('woff2');
  font-display: swap;
}

/** Montserrat Medium **/
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  src: url('./Montserrat-Medium.woff2') format('woff2');
  font-display: swap;
}

/** Montserrat SemiBold **/
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  src: url('./Montserrat-SemiBold.woff2') format('woff2');
  font-display: swap;
}

/** Montserrat Bold **/
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: url('./Montserrat-Bold.woff2') format('woff2');
  font-display: swap;
}
